@tailwind base;
@tailwind components;
@tailwind utilities;

// Global
.restrain {
    max-width: 1200px;
}

#side_nav.stick {
    position: fixed;
    top: 100px;
}

// Custom Text Area Styling
.custom-text-area {
    p, span, a, h1, h2, h3, h4, h5, h6 {
        &:last-child {
            margin-bottom: 0;
        }
    }
    h2, h3 {
        font-weight: bold;
    }
    h2 {
        font-size: 36px;
        line-height: 32px;
        color: #052647;
        margin-bottom: 25px;
    }
    h3 {
        font-size: 24px;
        line-height: 20px;
        color: #e4097e;
        margin-bottom: 20px;
    }
    img {
        float: left;
        padding-right: 30px;
        width: 300px;
    }
    p {
        color: #052647;
        margin-bottom: 20px;

        strong {
            font-weight: bolder;
        }
    }
    a {
        text-decoration: underline;
        color: #e4097e;
        font-weight: 700;

        &:hover {
            text-decoration: none;
        }
    }
    ol {
        margin: revert;
        padding: revert;
        list-style: auto;
    }
    ul {
        margin-left: 30px;
        margin-bottom: 30px;

        li {
            list-style-type: disc;

            p {
                margin-bottom: 2px;
                padding-bottom: 0 !important;
            }
        }
    }
    .faq {
        h2 {
            font-size: 28px;
            line-height: 33px;

            a {
                color: #e4097e;
                text-decoration: none;
            }
        }
    }
}

.box-unset {
    box-sizing: unset;
}

.custom-white-text-area h2 { font-size: 28px; line-height: 33px; color: #ffffff; font-weight: 700; text-align: center; margin-bottom: 20px; }
.custom-white-text-area h3 { font-size: 16px; line-height: 21px; color: #e4097e; text-transform: uppercase; margin-bottom: 15px; font-weight: bold;  }
.custom-white-text-area p  { margin-bottom: 20px; color: #ffffff; }
.custom-white-text-area p strong { color: #ffffff; font-weight: bolder; }
.custom-white-text-area p a { text-decoration: underline; color: #e4097e; }
.custom-white-text-area p a:hover { text-decoration: none; }
.custom-white-text-area ul { margin-left: 0px; margin-bottom: 30px; }
.custom-white-text-area ul li { list-style-type: none; padding-left: 30px; position: relative; padding-top: 5px; padding-bottom: 5px; }
.custom-white-text-area ul li::marker { display: none; }
.custom-white-text-area ul li::before { position: absolute; left: 0; top: 50%; transform: translateY(-50%); content: ''; background-image: url('https://smarta-statamic.s3.eu-west-2.amazonaws.com/tick.png'); background-repeat: no-repeat; background-size: contain; background-position: center; width: 24px; height: 21px; }
.custom-white-text-area ul li p { margin-bottom: 0; }

.custom-grey-text-area h3 { font-size: 16px; line-height: 21px; color: #e4097e; text-transform: uppercase; margin-bottom: 15px; font-weight: bold; }
.custom-grey-text-area p  { margin-bottom: 20px; color: #4d5762; }
.custom-grey-text-area p strong { color: #4d5762; font-weight: bolder; }
.custom-grey-text-area p a { text-decoration: underline; color: #e4097e; }
.custom-grey-text-area p a:hover { text-decoration: none; }
.custom-grey-text-area ul { margin-left: 0px; margin-bottom: 30px; }
.custom-grey-text-area ul li { list-style-type: none; padding-left: 30px; position: relative; padding-top: 5px; padding-bottom: 5px; }
.custom-grey-text-area ul li::marker { display: none; }
.custom-grey-text-area ul li::before { position: absolute; left: 0; top: 50%; transform: translateY(-50%); content: ''; background-image: url('https://smarta-statamic.s3.eu-west-2.amazonaws.com/tick.png'); background-repeat: no-repeat; background-size: contain; background-position: center; width: 24px; height: 21px; }
.custom-grey-text-area ul li p { margin-bottom: 0; color: #4d5762; }

.custom-grey-text-area.blue-text h3 { font-size: 16px; line-height: 21px; color: #e4097e; text-transform: uppercase; margin-bottom: 15px; }
.custom-grey-text-area.blue-text p  { margin-bottom: 20px; color: #052647; }
.custom-grey-text-area.blue-text p strong { color: #052647; font-weight: bolder; }
.custom-grey-text-area.blue-text p a { text-decoration: underline; color: #e4097e; }
.custom-grey-text-area.blue-text p a:hover { text-decoration: none; }
.custom-grey-text-area.blue-text ul { margin-left: 0px; margin-bottom: 30px; }
.custom-grey-text-area.blue-text ul li { list-style-type: none; padding-left: 30px; position: relative; padding-top: 5px; padding-bottom: 5px; }
.custom-grey-text-area.blue-text ul li::marker { display: none; }
.custom-grey-text-area.blue-text ul li::before { position: absolute; left: 0; top: 50%; transform: translateY(-50%); content: ''; background-image: url('https://smarta-statamic.s3.eu-west-2.amazonaws.com/tick.png'); background-repeat: no-repeat; background-size: contain; background-position: center; width: 24px; height: 21px; }
.custom-grey-text-area.blue-text ul li p { margin-bottom: 0; color: #4d5762; }
.custom-grey-text-area.blue-text ul li p strong { color: #4d5762; }

.custom-guide-description {max-width: 800px;}
.custom-guide-description h1 { font-size: 28px;font-weight:600; line-height:32px; color:#052647;margin-bottom: 24px; }
.custom-guide-description h2 { font-size: 22px;font-weight:600; line-height:32px; color:#052647;margin-bottom: 24px; }
.custom-guide-description p:not(table *):not(li p) { font-size: 18px;color:#4D5762;margin-bottom: 12px;padding-bottom:12px; }
.custom-guide-description li p { font-size: 18px;color:#4D5762; }
.custom-guide-description img:not(.profile-header) { padding-top:12px;padding-bottom:12px;}

.guide-block .custom-text-area p  { margin-bottom: 20px; color: #052647; }
.guide-block .custom-text-area p strong { font-weight: bolder;  }
.guide-block .custom-text-area p a { text-decoration: underline; color: #e4097e;font-weight: 700; }
.guide-block .custom-text-area p a:hover { text-decoration: none; }
.guide-block .custom-text-area ul { margin-left: 0px; margin-bottom: 30px; }
.guide-block .custom-text-area ul li { position: relative; list-style-type: none; padding-left: 30px; }
.guide-block .custom-text-area ul li::marker { display: none; }
.guide-block .custom-text-area ul li::before { position: absolute; left: 0; top: 50%; transform: translateY(-50%); content: ''; background-image: url('https://smarta-statamic.s3.eu-west-2.amazonaws.com/cirlcle-arrow.png'); background-repeat: no-repeat; background-size: contain; background-position: center; width: 24px; height: 21px; }
.guide-block .custom-text-area ul li p { margin-bottom: 2px;padding-bottom: 0 !important; }

.custom-guide-table { text-align: left;border-collapse: collapse;}
.custom-guide-table tr {}
.custom-guide-table tr th { padding:10px 15px;}
.custom-guide-table tr td { padding:10px 15px;}
.custom-guide-table table, th, td { border: 1px solid #e7e8e9; }
.custom-guide-table tr:nth-of-type(odd) { background-color: #f5f8fb; }
.custom-guide-table tr:nth-of-type(even) { background-color: #fff; }

// Detail Item
.detail-item img { display: inline-block; }
.detail-item { border: 1px solid #dee2e7; padding: 25px; }
.detail-item:nth-of-type(1), .detail-item:nth-of-type(5) { border-left: 0; }
.detail-item:nth-of-type(1), .detail-item:nth-of-type(2), .detail-item:nth-of-type(3), .detail-item:nth-of-type(4) { border-top: 0; }
.detail-item:nth-of-type(1), .detail-item:nth-of-type(2), .detail-item:nth-of-type(3), .detail-item:nth-of-type(4) { border-bottom: 0; }
.detail-item:nth-of-type(5), .detail-item:nth-of-type(6), .detail-item:nth-of-type(7), .detail-item:nth-of-type(8) { border-bottom: 0; }
.detail-item:nth-of-type(2), .detail-item:nth-of-type(3), .detail-item:nth-of-type(4), .detail-item:nth-of-type(6), .detail-item:nth-of-type(7), .detail-item:nth-of-type(8) { border-left: 0; }
.detail-item:nth-of-type(4), .detail-item:nth-of-type(8) { border-right: 0; }

.loan-application-steps {
    .loan-application-steps__grid {
        background: #dee2e7;
    }
    .loan-application-steps__item,
    .loan-application-steps__filler-item {
        background: #f5f8fb;
        padding: 25px;
    }
}

// Criteria Block
.criteria-block::before { content:''; position: absolute; background-image: url('https://smarta-statamic.s3.eu-west-2.amazonaws.com/arrow-spacer.png'); background-repeat: no-repeat; background-size: cover; width: 58px; height: 29px; top: 0; left: 50%; transform: translateX(-50%); }

.line-clamp-2{text-overflow: ellipsis;overflow: hidden;display: -webkit-box !important;-webkit-line-clamp: 2;-webkit-box-orient: vertical;white-space: normal;}
.line-clamp-4{text-overflow: ellipsis;overflow: hidden;display: -webkit-box !important;-webkit-line-clamp: 4;-webkit-box-orient: vertical;white-space: normal;}
.line-clamp-5{text-overflow: ellipsis;overflow: hidden;display: -webkit-box !important;-webkit-line-clamp: 5;-webkit-box-orient: vertical;white-space: normal;}

// Tabbed Accordion
.accordion-panel a,
.tabbed-accordion a,
.faqs-accordion a {
    text-decoration: underline;
    color: #e4097e;
}

// Table Styling
.repayment-block .table { display: block; }
.repayment-block .table table tr { border: 2px solid #ffffff; }
.repayment-block .table table tr th { width: 50%; height: 50px; text-align: center !important; padding: 10px !important; border: 2px solid #ffffff; }
.repayment-block .table table thead { color: #fff; font-size: 16px !important; line-height: 22px !important; font-weight: 700 !important; text-transform: uppercase !important; letter-spacing: .05em !important; font-family: 'Source Sans Pro' !important; background: #e4097e !important; border: 2px solid #ffffff; }
.repayment-block .table table thead tr { background: #e4097e !important; }
.repayment-block .table table tr td { text-align: center; color: #052647; font-size: 20px !important; line-height: 28px !important; font-weight: 700 !important; text-transform: none !important; letter-spacing: 0 !important; font-family: 'Source Sans Pro' !important; padding: 10px !important; border: 2px solid #ffffff; }
.repayment-block .table table tr:nth-of-type(even) { background-color: #eef0f3; }
.repayment-block .table table tr:nth-of-type(odd) { background-color: #dee2e7; }

// Option Block
.options .option .image:hover { background-color: #333333 !important; }

// Feefo widget styles
.feefo-review-carousel-widget-service .feefowidget-standard .feefowidget-carousel-summary .feefowidget-stars-row .feefowidget-rating-label {
    color: #fff !important;
    font-size: 68px !important;
    line-height: 46px !important;
    font-weight: 100 !important;
    text-transform: uppercase !important;
    letter-spacing: 0 !important;
}

.feefowidget-carousel-summary.feefowidget-carousel-summary-single-review.feefowidget-carousel-summary--award
{
    margin-right: 31px;
}

.feefo-review-carousel-widget-service .feefowidget-standard .feefowidget-carousel-body .feefowidget-carousel-single-review-body .feefowidget-sliders .feefowidget-slide .feefowidget-carousel-single-slide-content .feefowidget-review-content-outer .feefowidget-content-row, .feefo-review-carousel-widget-service .feefowidget-standard .feefowidget-carousel-body .feefowidget-carousel-single-review-body .feefowidget-sliders .feefowidget-showing-slide .feefowidget-carousel-single-slide-content .feefowidget-review-content-outer .feefowidget-content-row
{
    color: #fff;
    font-size: 16px !important;
    line-height: 20px !important;
    font-weight: 100 !important;
    text-transform: none !important;
    letter-spacing: 0 !important;
}

.feefo-review-carousel-widget-service .feefowidget-standard .feefowidget-carousel-body .feefowidget-carousel-single-review-body .feefowidget-sliders .feefowidget-slide .feefowidget-carousel-single-slide-content .feefowidget-meta-row, .feefo-review-carousel-widget-service .feefowidget-standard .feefowidget-carousel-body .feefowidget-carousel-single-review-body .feefowidget-sliders .feefowidget-showing-slide .feefowidget-carousel-single-slide-content .feefowidget-meta-row
{
    font-size: 16px !important;
    line-height: 20px !important;
    font-weight: 100 !important;
    text-transform: none !important;
    letter-spacing: 0 !important;
}
.feefo-review-carousel-widget-service .feefowidget-standard .feefowidget-carousel-body .feefowidget-carousel-single-review-body .feefowidget-sliders .feefowidget-slide .feefowidget-carousel-single-slide-content .feefowidget-title-row, .feefo-review-carousel-widget-service .feefowidget-standard .feefowidget-carousel-body .feefowidget-carousel-single-review-body .feefowidget-sliders .feefowidget-showing-slide .feefowidget-carousel-single-slide-content .feefowidget-title-row
{
    color: #ffd000 !important;
    font-size: 26px !important;
    line-height: 32px !important;
    font-weight: 100 !important;
    text-transform: none !important;
    letter-spacing: 0 !important;
}

.feefo-review-carousel-widget-service .feefowidget-standard .feefowidget-carousel-summary a
{
    margin-top: 12px !important;
    color: #ffd000 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    letter-spacing: .1em !important;
}

.feefowidget-carousel-single-review-body
{
    height:165px !important;
}
//  Form Styling
.form_block form input {
    //width: 100% !important;
}
.form_block input,
.form_block select,
fieldset textarea {
    width: 100% !important;
    border-color: #cbd6e2 !important;
    border-radius: 0.25rem !important;
}
.form_block input[type=radio],
input[type=checkbox]:not(.form-control) {
    width: 15px !important;
    height: 15px !important;
    margin-right: 5px;
}
.form_block label,
.form_block strong {
    text-align: left;
    display: block;
    font-weight: 600;
}
.hs-fieldtype-radio,
.hs-fieldtype-checkbox {
    margin-top: 20px !important;
}
.hs-fieldtype-radio ul span,
.hs-fieldtype-checkbox ul span,
.legal-consent-container span {
    font-weight: 500 !important;
}
.legal-consent-container span a {
    color:#e4097e !important;
}

.form-columns-0 { max-width: 100% !important;text-align:left; }

.form-columns-2 {
    width:100%;
    max-width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}
.form-columns-2 > div {
    width: calc((100% - 8px) / 2) !important;
}
.form-columns-2 .input {
    margin-right: 0 !important;
    width: 100%;
    display: block;
}
.form-columns-2 input {
    width: 100%;
    display: block;
}
.form-columns-1 {
    padding-top: 20px !important;
}
.form-columns-1 {
    width: 100%;
    max-width: 100% !important;
}
.form-columns-1 .input {
    margin-right: 0 !important;
}
.form-columns-1 .input .hs-input[type=email],
.hs-input[type=tel],
.hs-input[name=region] {
    width:100% !important;
}
.hs_submit input {
    background-color: #e4097e !important;
    color: white;
    border-radius: 0.25rem !important;
    width: max-content !important;
    padding: 10px 25px;
    margin: 15px;
    cursor: pointer;

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }
}
.apply_block::before {
    content: '';
    position: absolute;
    background-image: url('https://smarta-statamic.s3.eu-west-2.amazonaws.com/arrow-spacer.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 58px;
    height: 29px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.hs_submit {
    cursor: pointer;
}

.transmit-form {
    .form-field--label {
        @apply font-bold text-base flex mb-1 items-center tracking-wide w-full;
        line-height: 26px;
        transition: all 250ms ease-out;
    }
    .form-field--input {
        @apply block text-base border border-gray-200 text-left rounded tracking-wide w-full placeholder-gray-700 placeholder-opacity-50 shadow-none appearance-none px-3 py-1.5 m-0;
        box-sizing: border-box;
        transition: border-color 0.25s ease-in-out;
        line-height: 32px;

        &[disabled], &[readonly] {
            @apply opacity-50 cursor-not-allowed;
        }
    }
    textarea {
        @apply placeholder-gray-700 placeholder-opacity-50
    }
}

.transmit-columns {
    .transmit-columns__column {
        @apply w-full;

        @screen md {
            width: var(--width);
        }
    }
}

// Blue Form Block
.blue_form_block {
    color: #052647;

    p {
        color: #ffffff;
        display: block !important;
        text-align: left;
    }
    .hs-error-msgs {
        color: #ffffff;
    }
}

// hubspot form block
.hbspt-form input[type="email"] {width:100%;margin-bottom: 4px;border:1px solid #cbd6e2;border-radius: 4px;}
.hbspt-form input[type="checkbox"] { margin-top:5px; }

// Multi Video Block
.multi-video-block .video { position: relative; max-width: 100%; width: 900px; margin: 0 auto; }
.multi-video-block .video:before { display: block; content: ""; width: 100%; padding-top: 56.25%; }
.multi-video-block .video > iframe { position: absolute; top: 0; right: 0; bottom: 0; left: 0; width: 100%; height: 100%; }

// Mentors Block
.mentor-member .inner:hover { box-shadow: 0 10px 20px rgb(0 0 0 / 20%) !important; -webkit-box-shadow: 0 10px 20px rgb(0 0 0 / 20%) !important; -moz-box-shadow: 0 10px 20px rgba(0,0,0,.2) !important; transition: all .2s ease-in-out; }
.team-member:hover { padding-top: 10px; transition: all .2s ease-in-out; }
.team-member .inner:hover { box-shadow: 0 10px 20px rgb(0 0 0 / 20%) !important; -webkit-box-shadow: 0 10px 20px rgb(0 0 0 / 20%) !important; -moz-box-shadow: 0 10px 20px rgba(0,0,0,.2) !important; transition: all .2s ease-in-out; }

// Article Block
.article-image {
    transition-duration: 300ms;
}
.article-image .overlay {
    display: block;
    background-color: rgba(5,38,71,0.9);
    opacity: 0;
    transition-duration: 300ms;
}
.article-image .overlay::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    background-color: #e4097e;
    border-radius: 50%;
    background-image: url('https://smarta-statamic.s3.eu-west-2.amazonaws.com/link-white.png');
    background-position: center;
    background-size: 30px;
    background-repeat: no-repeat;
}
.article-image:hover .overlay {
    opacity: 100;
    transition-duration: 300ms;
}

// Triple List
.triple-block .list:hover .inner { box-shadow: 0 10px 20px rgb(0 0 0 / 20%) !important; -webkit-box-shadow: 0 10px 20px rgb(0 0 0 / 20%) !important; -moz-box-shadow: 0 10px 20px rgba(0,0,0,.2) !important; transition: all .2s ease-in-out; }
.triple-block .list:hover .inner .image { animation: animatedBackground 2s; animation-iteration-count: 1; background-size: 120%; opacity: 0.9; }

@keyframes animatedBackground {
  from {
    background-size: 100%;
  }
  to {
    background-size: 120%;
  }
}

// Media Queries
@media all and (max-width: 640px) {

	.detail-item { border: 0; }
}

.image__portrait {
    @apply aspect-[3/4];
}

.image__portrait_tall {
    @apply aspect-[1/2];
}

.image__square {
    @apply aspect-[1];
}

.image__landscape {
    @apply aspect-[4/3];
}

.image__none {
    @apply max-h-screen;

    img {
        object-fit: contain !important;
    }
}
